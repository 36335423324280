import React from 'react'
import Anticon from '../Anticon'
import AdminIcon from '../../../assets/icons/admin.svg'
import { Dropdown, Menu } from 'antd'
import { Link, usePage } from '@inertiajs/inertia-react'
import './AdminDropdown.scss'
import useAuthUser from '../../hooks/useAuthUser'
import { ROLES } from '../../constants/roles'
import useZiggy from '../../hooks/useZiggy'

const AdminDropdown = () => {
    const { hasRole, user } = useAuthUser()
    const { getRoute } = useZiggy()
    const { is_rp_rs_module_enabled } = usePage().props

    if (!hasRole([ROLES.RegionUnitAdmin, ROLES.RegionUnitSuperAdmin, ROLES.ConsultationAdmin])) {
        return null
    }

    const menuItems = [
        {
            label: (
                <Link data-cy="promoter" href={getRoute('real-estate.promoters.index')}>
                    Suivi des promoteurs
                </Link>
            ),
        },
        { label: <Link href={getRoute('real-estate.programs.region-unit.index')}>Suivi des programmes</Link> },
        {
            label: <Link href={getRoute('management-companies.index')}>Suivi des gestionnaires</Link>,
        },
        {
            label: <Link href={getRoute('management-companies.scpi-list.region-unit.index')}>Liste des SCPI</Link>,
        },

        is_rp_rs_module_enabled &&
            !hasRole([ROLES.ConsultationAdmin]) && {
                label: (
                    <Link href={encodeURI(`${getRoute('rp-rs.real-estate-agency.index')}`)}>
                        Liste des agences immobilières
                    </Link>
                ),
            },

        !hasRole([ROLES.ConsultationAdmin]) && {
            label: <Link href={getRoute('contact-directory.region-unit.externals')}>Gestion des contacts</Link>,
        },
        !hasRole([ROLES.ConsultationAdmin]) && {
            label: (
                <a href="/settings/resources/articles" target="_blank">
                    Gestion des actualités
                </a>
            ),
        },
        !hasRole([ROLES.ConsultationAdmin]) && {
            label: (
                <a href="/settings/resources/library-sub-themes" target="_blank">
                    Gestion de la bibliothèque
                </a>
            ),
        },
        !hasRole([ROLES.ConsultationAdmin]) && { label: <Link href={getRoute('exports.show')}>Exports</Link> },
        hasRole([ROLES.ConsultationAdmin]) && {
            label: <Link href={getRoute('contact-directory.internal-contact.index')}>Contacts internes</Link>,
        },
    ].filter(Boolean)

    return (
        <Dropdown
            className="AdminDropdown-trigger"
            overlayClassName="AdminDropdown-dropdown"
            placement="bottom"
            overlay={<Menu className="AdminDropdown-menu" items={menuItems} />}
            trigger={['click']}
        >
            <button data-cy="admin" className="btn-no-ui">
                <Anticon icon={AdminIcon} />
            </button>
        </Dropdown>
    )
}

export default AdminDropdown
